// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.






// {
//   "erp_base_url_account": "http://192.168.10.78:4568",
//   "erp_base_url_auth": "http://192.168.10.78:4555",
//   "erp_base_url_master": "http://192.168.10.78:5560", 
//   "erp_base_url_sale": "http://192.168.10.78:4568",
//   "erp_base_url_inventory": "http://192.168.10.78:5000",
//   "erp_base_url_fixedAsset": "http://192.168.10.78:5055",
//   "erp_base_url_purchaseService":"http://192.168.10.78:5561",
//   "erp_base_url_investment": "http://192.168.10.78:4066",
//   "erp_base_url_report": "http://192.168.10.78:5005"
// }

// {
//   "erp_base_url_account": "http://23.27.126.234:8080/waldosales",
//   "erp_base_url_auth": "http://23.27.126.234:8080/waldoauth",
//   "erp_base_url_master": "http://23.27.126.234:8080/waldomaster", 
//   "erp_base_url_sale": "http://23.27.126.234:8080/waldosales",
//   "erp_base_url_inventory": "http://23.27.126.234:8080/waldoinventory",
//   "erp_base_url_fixedAsset": "http://23.27.126.234:8080/fixedAsset",
//   "erp_base_url_purchaseService":"http://23.27.126.234:8080/purchaseService",
//   "erp_base_url_investment": "http://23.27.126.234:8080/investment",
//   "erp_base_url_report": "http://23.27.126.234:8080/waldoreport"
// } 

// {
//   "erp_base_url_account": "http://70.35.202.45:8085/waldosales",
//   "erp_base_url_auth": "http://70.35.202.45:8085/waldoauth",
//   "erp_base_url_master": "http://70.35.202.45:8085/waldomaster", 
//   "erp_base_url_sale": "http://70.35.202.45:8085/waldosales",
//   "erp_base_url_inventory": "http://70.35.202.45:8085/waldoinventory",
//   "erp_base_url_fixedAsset": "http://70.35.202.45:8085/fixedAsset",
//   "erp_base_url_purchaseService":"http://70.35.202.45:8085/purchaseService",
//   "erp_base_url_investment": "http://70.35.202.45:8085/investment",
//   "erp_base_url_report": "http://70.35.202.45:8085/waldoreport"
// } 

// recent local
// {
//   "erp_base_url_account": "http://192.168.10.78:4455",
//   "erp_base_url_auth": "http://192.168.10.78:4555",
//   "erp_base_url_master": "http://192.168.10.78:5560", 
//   "erp_base_url_sale": "http://192.168.10.78:4568",
//   "erp_base_url_inventory": "http://192.168.10.78:5000",
//   "erp_base_url_fixedAsset": "http://192.168.10.78:5055",
//   "erp_base_url_purchaseService":"http://192.168.10.78:5561",
//   "erp_base_url_investment": "http://192.168.10.78:4077",
//   "erp_base_url_report": "http://192.168.10.78:4066"
// }


// 70.
// {
//   "erp_base_url_account": "http://70.35.202.45:8085/demowaldosales",
//   "erp_base_url_auth": "http://70.35.202.45:8085/demowaldoauth",
//   "erp_base_url_master": "http://70.35.202.45:8085/demowaldomaster", 
//   "erp_base_url_sale": "http://70.35.202.45:8085/demowaldosales",
//   "erp_base_url_inventory": "http://70.35.202.45:8085/demowaldoinventory",
//   "erp_base_url_fixedAsset": "http://70.35.202.45:8085/demofixedAsset",
//   "erp_base_url_purchaseService":"http://70.35.202.45:8085/demopurchaseService",
//   "erp_base_url_investment": "http://70.35.202.45:8085/demoinvestment",
//   "erp_base_url_report": "http://70.35.202.45:8085/demowaldoreport"
// } 
