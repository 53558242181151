<router-outlet ></router-outlet>
<!-- <button (click)="addToHomeScreen()" class="p-3 m-4">aA</button>
<button class="add-button p-3 m-4">Add to home screen</button> -->

<!-- <button (click)="onbeforeinstallprompt($event)" class="p-3 m-4">aA45</button> -->
<!-- <img src="../../ngsw-config.json" alt=""> -->
<!-- <button (click)="onbeforeinstallprompt($event)">2323</button> -->
<!-- <div>
    <h2>{{ 'HOME.TITLE' | translate }}</h2> 
    <label>
      {{ 'HOME.SELECT' | translate }}
      <select #langSelect (change)="changeLAng(langSelect.value)" (change)="translate.use(langSelect.value)">
        <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === defaultLang">{{ lang }}</option>
      </select>
    </label>

  </div> -->