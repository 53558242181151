import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotfoundComponent } from './notfound/notfound.component';

console.log(window.location.hash.split('/')[1]);
if(window.location.hash.split('/')[1]=='creationsinfra'){
  localStorage.setItem("waldo_sub_domain","creationsinfra");
}else if(window.location.hash.split('/')[1]=='waldoerp'){
  localStorage.setItem("waldo_sub_domain","waldoerp");
}
const routes: Routes = [
  
  { path: '', redirectTo: '/notfound', pathMatch: 'full' },
  { path: 'login', redirectTo: '/notfound', pathMatch: 'full' },
  { path: 'notfound', component: NotfoundComponent },

  { path: '', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },

  
  { path: 'creationsinfra', redirectTo: 'creationsinfra/login', pathMatch: 'full' },
  { path: 'test', redirectTo:  'test/login', pathMatch: 'full' },
  { path: 'waldoerp', redirectTo:  'waldoerp/login', pathMatch: 'full' },


  { path:  'creationsinfra/login', loadChildren: () => import('./indexlogin/indexlogin.module').then(m => m.IndexloginModule) },
  { path:  'test/login', loadChildren: () => import('./indexlogin/indexlogin.module').then(m => m.IndexloginModule) },
  { path:  'waldoerp/login', loadChildren: () => import('./indexlogin/indexlogin.module').then(m => m.IndexloginModule) },
//  -----------------------

]


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled', useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

    // console.log(this.router.url);
    // console.log(this.location.path());
    // console.log(router.routerState.snapshot.url);
    // const source = interval(500);
    // this.subscription = source.subscribe(val => this.getListRote());