import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { httpInterceptorProviders } from './interceptor';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
// import { TooltipModule } from 'ngx-bootstrap/tooltip';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/lang/', '.json');
} 
@NgModule({
  declarations: [
    AppComponent      
  ], 
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }), 
    AppRoutingModule,BrowserAnimationsModule,
    HttpClientModule,ModalModule.forRoot(),
    // TooltipModule.forRoot(),
    TranslateModule.forRoot({
      loader: { 
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },isolate:true
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production ,registrationStrategy: "registerImmediately"}) 
  ],
  providers: [httpInterceptorProviders], 
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]   
})
export class AppModule { } 
