import { Component, HostListener } from '@angular/core';
// import { TranslateService } from '@ngx-translate/core';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'WALDO-ERP';
  // defaultLang: string;
  constructor(
    // public translate: TranslateService
    ) {
      this.registerServiceWorker();
      $(window).on('beforeinstallprompt', function(e) {
        console.log('beforeinstallprompt Event fired');
        // alert(123)
        e.preventDefault();
        this.deferredPrompt = e.originalEvent;
    
        return false;
    });
  
    // translate.addLangs(['en', 'fr']);
    // this.defaultLang=localStorage.getItem('lang');
    // translate.setDefaultLang(this.defaultLang);
    // localStorage.setItem('lang',this.defaultLang)

  }
  registerServiceWorker = async () => {  
    console.log(navigator);
    if ('serviceWorker' in navigator) {
      // alert(1);
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('src/sw.js')
        .then((registration) => {
          console.log('Service Worker registration completed with scope: ',
            registration.scope)
        }, (err) => {
          console.log('Service Worker registration failed', err)
        })
      })
    } else {
      console.log('Service Workers not supported')
    }
  };
  // changeLAng(id):void{
  //   localStorage.setItem('lang',id)

  // }
  deferredPrompt: any;
  showButton = false;
  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    console.log(e);
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    this.showButton = true;
    console.log(this.deferredPrompt);
  }
  addToHomeScreen() {
    // hide our user interface that shows our A2HS button
    this.showButton = false;
    // Show the prompt
  
      // if (this.deferredPrompt) {
        // alert(this.deferredPrompt);
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
    .then((choiceResult) => {
    if (choiceResult.outcome === 'accepted') {
      console.log('User accepted the A2HS prompt');
    } else {
      console.log('User dismissed the A2HS prompt'); 
    }
    this.deferredPrompt = null;
  });
      // }
  
  }
} 
